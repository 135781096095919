import { createContext } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import type { AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { appWithTranslation } from 'next-i18next'
import * as Sentry from '@sentry/react'

import GlobalStyle from '@/features/shared/styles/globalstyles'
import RouteRender from '@/features/shared/RouteRender'
import SignalRWrapper from '@/features/signalR/components/SignalRWrapper'
import Authorization from '@/features/authorization/components/Authorization'
import { getAppVersion } from '@/lib/getAppValues'
import { isDev } from '@/lib/development'

if (!isDev()) {
  Sentry.init({
    dsn: 'https://15c06715d3f0f86390bdf1dc030dfa71@o4505679619358720.ingest.sentry.io/4505680682483712',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost'],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

// Route
const routeContextValue = {
  about: { hasLoggedInVersion: true, hasLoggedOutVersion: false },
  onboarding: {
    language: { hasLoggedInVersion: true, hasLoggedOutVersion: true },
    cargocard: { hasLoggedInVersion: true, hasLoggedOutVersion: true },
  },
  visit: {
    hasLoggedInVersion: true,
    hasLoggedOutVersion: true,
    detail: {
      activity: { hasLoggedInVersion: true, hasLoggedOutVersion: true },
      details: { hasLoggedInVersion: true, hasLoggedOutVersion: true },
    },
  },
}
export const RouteContext = createContext(routeContextValue)

// Query
export const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 0, refetchOnWindowFocus: false } },
})

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>ECT app - v{getAppVersion()}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Head>
      <Script id="google-tag-manager">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', "GTM-KFLKBLM");`}
      </Script>
      <Script id="google-tag-manager-initialize">
        {`window.dataLayer = window.dataLayer || [];`}
      </Script>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <SignalRWrapper>
          <RouteContext.Provider value={routeContextValue}>
            <Authorization>
              <RouteRender>
                <Component {...pageProps} />
              </RouteRender>
            </Authorization>
          </RouteContext.Provider>
        </SignalRWrapper>
      </QueryClientProvider>
    </>
  )
}

export default appWithTranslation(App)
