import { ProfileSettingsPayload } from '@/features/profile/types'

export const MOBILE_FCM_TOKEN = 'MOBILE_FCM_TOKEN'
export const getFcmToken = (): string =>
  (typeof window !== 'undefined' && sessionStorage.getItem(MOBILE_FCM_TOKEN)) ||
  ''

/**
 * Whether the user has accepted or rejected push notifications.
 * The empty string means the user has not yet been prompted to accept push notifications.
 * On older versions of Android, this will always be GRANTED.
 */
export const PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS'
export const getPushNotifications = (): 'GRANTED' | 'DENIED' | '' =>
  ((typeof window !== 'undefined' &&
    sessionStorage.getItem(PUSH_NOTIFICATIONS)) ||
    '') as 'GRANTED' | 'DENIED' | ''

export const LOCALE = 'LOCALE'
export const getLocale = (): 'en' | 'nl' =>
  ((typeof window !== 'undefined' && sessionStorage.getItem(LOCALE)) ||
    'nl') as 'en' | 'nl'

export const INSTALLATION_ID = 'INSTALLATION_ID'
export const setInstallationId = (id: string) =>
  (typeof window !== 'undefined' &&
    sessionStorage.setItem(INSTALLATION_ID, id)) ||
  ''

export const getInstallationId = () =>
  (typeof window !== 'undefined' && sessionStorage.getItem(INSTALLATION_ID)) ||
  undefined

/**
 * Value returned by https://github.com/react-native-device-info/react-native-device-info#getversion
 */
export const APP_VERSION = 'APP_VERSION'
export const getAppVersion = () =>
  (typeof window !== 'undefined' && sessionStorage.getItem(APP_VERSION)) || ''

export const setAppVersion = (value: string) =>
  (typeof window !== 'undefined' &&
    sessionStorage.setItem(APP_VERSION, value)) ||
  ''

/**
 * Value returned by https://github.com/react-native-device-info/react-native-device-info#getversion
 */
export const DEVICE_ID = 'DEVICE_ID'

/**
 * Fixed value, the intention is to indicated that a given website is running inside the app.
 */
export const ECTAPP = 'ECTAPP'

export const ONBOARDING = 'ONBOARDING'
export const getOnboardingValues = () => {
  const data = typeof window !== 'undefined' && localStorage.getItem(ONBOARDING)
  return data && JSON.parse(data)
}

export const setOnboardingValues = (values: ProfileSettingsPayload) =>
  (typeof window !== 'undefined' &&
    localStorage.setItem(ONBOARDING, JSON.stringify(values))) ||
  ''

export const removeOnboardingValues = () =>
  typeof window !== 'undefined' && localStorage.removeItem(ONBOARDING)
