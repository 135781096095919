import { mobileAccessTokenKey, secureLogisticsDataKey } from '@/config'
import { SLProfileResponse } from '@/features/authorization/types'
import { useSecureLogisticsStore } from '@/features/secureLogistics/store'

// ACCESS TOKEN

export const getAccessToken = (): string => {
  if (typeof window !== 'undefined') {
    const accessToken = sessionStorage.getItem(mobileAccessTokenKey)
    return accessToken && accessToken !== 'undefined' ? accessToken : ''
  }
  return ''
}
export const removeAccessToken = (): void => {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(mobileAccessTokenKey)
    window.dispatchEvent(new Event('storage'))
  }
}
export const setAccessTokenToStorage = (accessToken?: string) => {
  sessionStorage.setItem(mobileAccessTokenKey, accessToken || '')
  window.dispatchEvent(new Event('storage'))
}

// SECURE LOGISITICS

export const getSecureLogisticFromStorage = (): SLProfileResponse | null => {
  if (typeof window !== 'undefined') {
    const data = localStorage.getItem(secureLogisticsDataKey)
    return data ? JSON.parse(data) : null
  }
  return null
}

export const setSecureLogisticToStorage = (data: SLProfileResponse) => {
  const setAccessToken = useSecureLogisticsStore.getState().setAccessToken
  setAccessToken(data.accessToken)
  if (typeof window !== 'undefined') {
    localStorage.setItem(secureLogisticsDataKey, JSON.stringify(data))
    window.dispatchEvent(new Event('storage'))
  }
}

// UTILS

export const isExpired = (exp: string): boolean =>
  new Date(exp).valueOf() < (new Date().getTime() + 1) / 1000

export const isSecureLogisticsFromStorageValid = (): boolean => {
  const SLData = getSecureLogisticFromStorage()
  return !!(SLData && !isExpired(SLData.expires))
}

export const getAnyAccessToken = (): {
  token: string
  type: 'STS' | 'SL'
} | null => {
  const stsAccessToken = getAccessToken()
  const SLData = getSecureLogisticFromStorage()

  return !!stsAccessToken
    ? { token: stsAccessToken, type: 'STS' }
    : SLData
      ? { token: SLData.accessToken, type: 'SL' }
      : null
}
