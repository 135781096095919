import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { isDev } from '@/lib/development'

type UserStateType = 'idle' | 'loggedIn' | 'loggedOut' | 'refreshed'

type State = {
  accessToken?: string
  userStateChangedIn: UserStateType
  loggedIn: boolean
}

type Actions = {
  setAccessToken: (token: string) => void
  setLoggedIn: (loggedIn: boolean) => void
  setUserStateChangedIn: (state: UserStateType) => void
}

export const useAuthorizationStore = create<State & Actions>((set) => ({
  userStateChangedIn: 'idle',
  loggedIn: false,
  setAccessToken: (token: string) => set((state) => ({ accessToken: token })),
  setLoggedIn: (loggedIn: boolean) =>
    set((state) => ({
      loggedIn,
    })),
  setUserStateChangedIn: (userState: UserStateType) =>
    set((state) => ({
      userStateChangedIn: userState,
    })),
}))

if (isDev()) {
  mountStoreDevtool('AuthorizationStore', useAuthorizationStore)
}
