import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import api from '@/lib/api'
import { getAccessToken } from '@/lib/getAccessToken'
import { Company, CompanyResponse } from './types'

export const companyQueryKey = ['company']

export const useGetCompany = (options?: UseQueryOptions) => {
  const accessToken = getAccessToken()
  return useQuery<CompanyResponse, unknown, Company, any>({
    queryKey: companyQueryKey,
    queryFn: () =>
      api(
        `${process.env.NEXT_PUBLIC_ECT_API_URL}/RPProfiles/api/Company/v1/company`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Ocp-Apim-Subscription-Key': `${process.env.NEXT_PUBLIC_ECT_API_KEY}`,
          },
        },
        'STS',
      ),
    select: (data: CompanyResponse) =>
      data.companies.find((c) => c.isActiveCompany),
    ...options,
    enabled:
      options && typeof options.enabled === 'boolean'
        ? options.enabled && !!accessToken
        : !!accessToken,
  })
}
